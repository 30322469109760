import { createUseStyles } from 'react-jss';

export default createUseStyles({
  title: {
    fontFamily: 'Work Sans',
    fontWeight: 500,
    marginBottom: 0,
  },

  about: {
    fontFamily: 'Work Sans',
  },
});
