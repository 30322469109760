import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Texts from '../Texts';
import BankProps from '../BankProps';
import DropDown from '../DropDown';
import Payment from '../Payment';
import BlockText from '../BlockText';

const Donates = ({ title, classes, ...rest }) => {
  const { dataJson } = useStaticQuery(graphql`
    {
      dataJson {
        pages {
          donates {
            content
            patrons {
              title
              content
            }
            legate {
              title
              content
            }
          }
        }
      }
    }
  `);
  const { content, patrons, legate } = dataJson.pages.donates;

  return (
    <div className={classes.container}>
      <p className={classes.title}>{title}</p>
      <Texts content={content} className={classes.content} />
      <BankProps />
      <DropDown title={patrons.title}>
        <BlockText content={patrons.content} />
      </DropDown>
      <DropDown title={legate.title}>
        <Texts content={legate.content} className={classes.legateContent} />
        <Payment {...rest} />
      </DropDown>
    </div>
  );
};

Donates.defaultProps = {
  title: null,
};

Donates.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.string,
};

export default Donates;
