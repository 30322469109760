/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Texts = ({ content, classes, className }) => (
  <div className={classNames([classes.textContainer, className])}>
    {content.map((item, i) => (
      <p key={i} className={classes.text}>
        {item}
      </p>
    ))}
  </div>
);

Texts.defaultProps = {
  className: null,
};

Texts.propTypes = {
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

export default Texts;
