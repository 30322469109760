import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

const Head = ({ location }) => {
  const { pathname } = location;
  const { site, file } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          author
          description
          keywords
          title
        }
      }
      file(relativePath: { eq: "og_image.png" }) {
        publicURL
      }
    }
  `);
  const { title, description, author, keywords } = site.siteMetadata;
  const kws = useMemo(() => keywords.join(','), [keywords]);
  const baseUrl = process.env.GATSBY_URL;

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="author" content={author} />
      <meta name="keywords" content={kws} />
      <meta name="title" property="og:title" content={title} />
      <meta name="description" property="og:description" content={description} />
      <meta property="og:url" content={`${baseUrl}${pathname}`} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={title} />
      {file && <meta property="og:image" content={baseUrl + file.publicURL} />}
      {file && <meta property="og:image:secure_url" content={baseUrl + file.publicURL} />}
      <meta property="og:image:alt" content="logo" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:site" content={baseUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {file && <meta name="twitter:image" content={baseUrl + file.publicURL} />}
      {file && <meta name="twitter:image:src" content={baseUrl + file.publicURL} />}
      <link rel="canonical" href={`${baseUrl}${pathname}`} />
    </>
  );
};

Head.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Head;
