/* eslint-disable react/no-unused-state */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Clipboard from 'clipboard';

import Links from '../Links';

const Payment = ({ classes }) => {
  const { dataJson } = useStaticQuery(graphql`
    {
      dataJson {
        pages {
          donates {
            legate {
              payment {
                content
                placeholder
                copy {
                  button
                  message
                }
                about
                link {
                  name
                  url
                }
              }
            }
          }
        }
      }
    }
  `);
  const [sum, setSum] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const inputButton = useRef(null);

  useEffect(() => {
    const clipboard = new Clipboard(inputButton.current);
    if (inputButton.current) {
      clipboard.on('success', () => setCopySuccess(true));
    }
    return () => {
      if (clipboard) {
        clipboard.destroy();
      }
    };
  }, []);

  const handleChange = useCallback(
    (event) => {
      setSum(Math.abs(event.target.value) || '');
      setCopySuccess(false);
    },
    [setSum, setCopySuccess]
  );

  const { content, placeholder, copy, about, link } = dataJson.pages.donates.legate.payment;

  return (
    <>
      <div className={classes.container}>
        <p>
          {content}{' '}
          <input type="number" placeholder={placeholder} value={sum} onChange={handleChange} className={classes.input} />.
        </p>
      </div>
      <div className={classes.btnContainer}>
        <button
          type="button"
          data-clipboard-action="copy"
          data-clipboard-text={`${content} ${Number(sum) > 0 ? sum : 0}.`}
          ref={inputButton}
          className={classes.button}
        >
          {copy.button}
        </button>
        <p className={classes.message}>{copySuccess && copy.message}</p>
      </div>
      <p className={classes.about}>{about}</p>
      <Links links={[link]} />
    </>
  );
};

Payment.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Payment;
