export default ({ spacing, text }) => {
  const { fontSize, lineHeight } = text.main;

  return {
    container: {
      '@media (min-width: 1100px) and (max-width: 1600px)': {
        width: spacing * 60,
      },
    },

    title: {
      ...text.dropDown,
      fontWeight: 500,
      margin: 0,
      marginBottom: spacing * 3,
    },

    content: {
      marginBottom: fontSize * lineHeight,
    },

    legateContent: {
      marginBottom: spacing * 2.75,
    },
  };
};
