import { createUseStyles } from 'react-jss';

export default createUseStyles(({ button, spacing, text, colors }) => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: button.height,
    width: '100%',
    paddingLeft: spacing * 2,
    borderRadius: 5,
    background: colors.dropDown,
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    ...text.dropDown,
  },

  title: {
    margin: 0,
  },

  image: {
    marginRight: spacing * 2,
  },

  contentContainer: {
    overflow: 'hidden',
    height: 0,
    opacity: 0,
    marginBottom: spacing * 0.75,
    paddingTop: spacing * 1.5,
  },
}));

export const animateBoxProps = {
  variants: {
    open: { height: 'auto', opacity: 1 },
    close: { height: 0, opacity: 0 },
  },
  transition: { duration: 0.5 },
  initial: false,
};
