import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import BlockText from '../BlockText';

import useStyles from './styles';

const BankProps = () => {
  const classes = useStyles();
  const { dataJson } = useStaticQuery(graphql`
    {
      dataJson {
        pages {
          donates {
            bank_props {
              title
              own
              international
              about
            }
          }
        }
      }
    }
  `);
  const { title, own, international, about } = dataJson.pages.donates.bank_props;

  return (
    <>
      <BlockText content={title} className={classes.title} />
      <BlockText content={own} />
      <BlockText content={international} />
      <BlockText content={about} className={classes.about} />
    </>
  );
};

export default BankProps;
