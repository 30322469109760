import React, { useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import plusSvg from '@/assets/images/plus.svg';
import minusSvg from '@/assets/images/minus.svg';

import useStyles, { animateBoxProps } from './styles';

const DropDown = ({ title, children }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = useCallback(() => setIsOpen(($) => !$), []);

  return (
    <div>
      <button type="button" onClick={handleClick} className={classes.titleContainer}>
        <p className={classes.title}>{title}</p>
        <img src={isOpen ? minusSvg : plusSvg} alt="show/hide" className={classes.image} />
      </button>
      <motion.div className={classes.contentContainer} animate={isOpen ? 'open' : 'close'} {...animateBoxProps}>
        {children}
      </motion.div>
    </div>
  );
};

DropDown.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default DropDown;
