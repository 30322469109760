export default ({ text }) => {
  const { fontSize, lineHeight } = text.main;

  return {
    textContainer: {
      marginBottom: 2 * fontSize * lineHeight,
    },

    text: {
      margin: 0,
      marginBottom: fontSize * lineHeight,
    },
  };
};
