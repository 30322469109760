export default ({ text, spacing }) => ({
  title: {
    ...text.main,
    color: 'rgba(0, 58, 71, 0.65)',
    margin: [2, 0],
  },

  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  link: {
    ...text.link,
    display: 'inline-block',
    margin: [3, 0],
  },

  arrow: {
    position: 'relative',
    top: 1,
    marginRight: spacing * 1.5,
  },
});
