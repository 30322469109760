/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classesNames from 'classnames';

const BlockText = ({ content, classes, children, className }) => (
  <div className={classesNames([classes.block, className])}>
    {content ? content.map((item, i) => <p key={i}>{item}</p>) : children}
  </div>
);

BlockText.defaultProps = {
  content: null,
  children: null,
  className: null,
};

BlockText.propTypes = {
  content: PropTypes.arrayOf(PropTypes.string),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};

export default BlockText;
