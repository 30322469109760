/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import arrowSvg from '@/assets/images/arrow.svg';

const Links = ({ title, links, classes, ...rest }) => (
  <div {...rest}>
    {Boolean(title) && <p className={classes.title}>{title}</p>}
    <div className={classes.linkContainer}>
      {links.map(({ name, url }, i) => (
        <a key={i} href={url} className={classes.link} rel="noopener noreferrer" target="_blank">
          <img src={arrowSvg} alt={name} className={classes.arrow} />
          {name}
        </a>
      ))}
    </div>
  </div>
);

Links.defaultProps = {
  title: null,
};

Links.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      links: PropTypes.string,
    })
  ).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Links;
