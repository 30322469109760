export default ({ text }) => {
  const { fontSize, lineHeight } = text.main;

  return {
    block: {
      marginBottom: fontSize * lineHeight,
      '&>p': {
        margin: 0,
      },
    },
  };
};
