export default ({ spacing, button, colors, text }) => ({
  container: {
    border: [1, 'solid', '#b5c6c8'],
    borderRadius: 5,
    background: 'rgba(0,58,71,0.04)',
    padding: [spacing * 1.5, spacing * 3.25, spacing * 1.5, spacing * 2.25],
    '&>p': {
      fontFamily: 'Work Sans',
      fontSize: 15,
      fontWeight: 500,
      lineHeight: 1.53,
      margin: 0,
    },
    marginBottom: spacing * 1.5,
  },

  input: {
    border: 'none',
    borderBottom: [1, 'solid', '#003a47'],
    font: 'inherit',
    color: 'inherit',
    background: 'none',
    width: spacing * 8.5,
    outline: 'none',
    '&::placeholder': {
      color: 'rgba(0,58,71,0.4)',
    },
    '&:-ms-input-placeholder': {
      color: 'rgba(0,58,71,0.4)',
    },
  },

  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing * 4.5,
  },

  button: {
    minHeight: button.height,
    ...text.dropDown,
    padding: [spacing * 1.5, spacing * 2],
    outline: 'none',
    color: colors.white,
    border: 'none',
    borderRadius: 5,
    backgroundColor: colors.active,
    '&:hover': {
      backgroundColor: colors.hover,
    },
    '&:active': {
      backgroundColor: colors.click,
    },
  },

  message: {
    marginLeft: spacing * 2,
    ...text.dropDown,
    color: text.link.color,
    fontSize: 12,
    lineHeight: 1.58,
  },

  about: {
    margin: 0,
    marginBottom: spacing * 2.75,
  },

  '@media (max-width: 800px)': {
    btnContainer: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: spacing * 1.25,
    },

    button: {
      width: '100%',
    },

    message: {
      height: spacing * 4,
      margin: 0,
      marginTop: spacing,
    },
  },
});
